const storagePrefix = 'estorelogistics';

export const freightEnquiryUrl = 'https://www.estorelogistics.com.au/freight-enquiry';

export const helpUrl =
  'https://help.estorelogistics.com.au/login/jwt/token?hd_jwt=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjIxNDc0NzIwMDAsImlhdCI6MTUzMTU0MDE1MywiYXVkIjoiaHR0cHM6Ly9lc3RvcmVsb2dpc3RpY3MuaGVscGRvY3MuaW8iLCJwZXJtaXNzaW9uX2dyb3VwcyI6WyJncm91cDpxcnY3eGEwbGNoIl19.M3nx9sKHfGTOyNu9GFB28Y6k6tfyst-UPeKWCfPrERo&forward=https://help.estorelogistics.com.au/category/a374tp9lxc-express-user-guide';

export const storageName = {
  user: `${storagePrefix}:user`,
  activeCompany: `${storagePrefix}:active-company`,
  salesOrderFilter: `${storagePrefix}${process?.env.REACT_APP_VERSION}:so-filter`,
  salesOrderColumn: `${storagePrefix}${process?.env.REACT_APP_VERSION}:so-column`,
  returnsFilter: `${storagePrefix}${process?.env.REACT_APP_VERSION}:returns-filter`,
  returnsColumn: `${storagePrefix}${process?.env.REACT_APP_VERSION}:returns-column`,
  inboundFilter: `${storagePrefix}${process?.env.REACT_APP_VERSION}:inbound-filter`,
  inboundColumn: `${storagePrefix}${process?.env.REACT_APP_VERSION}:inbound-column`,
  inboundProductColumn: `${storagePrefix}${process?.env.REACT_APP_VERSION}:inbound-product-column`,
  inventoryFilter: `${storagePrefix}${process?.env.REACT_APP_VERSION}:inventory-filter`,
  inventoryColumn: `${storagePrefix}${process?.env.REACT_APP_VERSION}:inventory-column`,
  inventoryMovementColumn: `${storagePrefix}${process?.env.REACT_APP_VERSION}:inventory-movement-column`,
  importFileNameSalesOrder: `${storagePrefix}${process?.env.REACT_APP_VERSION}:import-file-name-so`,
  importedSalesOrder: `${storagePrefix}${process?.env.REACT_APP_VERSION}:imported-so`,
  importedSalesOrderColumns: `${storagePrefix}${process?.env.REACT_APP_VERSION}:imported-so-columns`,
  importedSalesOrderValidation: `${storagePrefix}${process?.env.REACT_APP_VERSION}:imported-so-validation`,
  importFileNameInbound: `${storagePrefix}${process?.env.REACT_APP_VERSION}:import-file-name-inbound`,
  importedInbound: `${storagePrefix}${process?.env.REACT_APP_VERSION}:imported-inbound`,
  importFileNameInventory: `${storagePrefix}${process?.env.REACT_APP_VERSION}:import-file-name-inventory`,
  importedInventory: `${storagePrefix}${process?.env.REACT_APP_VERSION}:imported-inventory`,
  importedInventoryColumns: `${storagePrefix}${process?.env.REACT_APP_VERSION}:imported-inv-columns`,
  bomFilter: `${storagePrefix}${process?.env.REACT_APP_VERSION}:bom-filter`,
  bomColumn: `${storagePrefix}${process?.env.REACT_APP_VERSION}:bom-column`,
  dgItemProfileFilter: `${storagePrefix}${process?.env.REACT_APP_VERSION}:dgItemProfile-filter`,
  dgItemProfileColumn: `${storagePrefix}${process?.env.REACT_APP_VERSION}:dgItemProfile-column`,
  inventoryNavGroupCls: `${storagePrefix}${process?.env.REACT_APP_VERSION}:inventoryNavGroupCls`,
  inventoryNavGroupActive: `${storagePrefix}${process?.env.REACT_APP_VERSION}:inventoryNavGroupActive`,
};

export const trigger = {
  salesOrderEdit: { HEADER: 'header', DETAIL: 'detail' },
  inboundDeliveryEdit: { HEADER: 'header', DETAIL: 'detail' },
  inventoryEdit: { HEADER: 'header', DETAIL: 'detail' },
  bomEdit: { HEADER: 'header', DETAIL: 'detail' },
};
